.customAdminBackground {
  background: rgb(250, 68, 68);
  background: -moz-linear-gradient(
    267deg,
    rgba(250, 68, 68, 1) 0%,
    rgba(14, 122, 173, 1) 100%
  );
  background: -webkit-linear-gradient(
    267deg,
    rgba(250, 68, 68, 1) 0%,
    rgba(14, 122, 173, 1) 100%
  );
  background: linear-gradient(
    267deg,
    rgba(250, 68, 68, 1) 0%,
    rgba(14, 122, 173, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fa4444",endColorstr="#0e7aad",GradientType=1);
}

.question {
  position: absolute;
  top: 150px;
  right: 50px;
  cursor: pointer;
}
svg#quest {
  display: block;
  margin: auto;
}
.instr {
  margin-top: 10px;
  color: white;
}
.editable_title {
  margin-left: 0.9rem;
  width: 80%;
  border: none;
  border-bottom: 1px solid black;
}
.editable_title:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}
a {
  color: white;
}
a:hover {
  color: white;
}

@media (max-width: 576px) {
  .question {
    top: 113px;
    right: 8px;
  }
  svg#quest {
    height: 25px;
    width: 25px;
  }
  .instr {
    display: none;
  }
  .editable_title {
    margin-left: 0;
    margin-top: 10px;
    width: 90%;
  }
}
