@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
* {
  margin: 0;
  padding: 0;
  --plyr-color-main: red;
  --plyr-badge-background: red;
}
.plyr--stopped .plyr__controls {
  opacity: 0;
  pointer-events: none;
}
