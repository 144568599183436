@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Zilla+Slab+Highlight:wght@700&display=swap");

.custom_btn {
  border-radius: 29px;
  padding-left: 4rem;
  padding-right: 4rem;
  position: relative;
  z-index: 100;
  margin: 10px 0;
}

.custom_desc {
  font-size: 1.5rem;
}

/* .cover {
  position: absolute;
  background-image: url("../assets/cover.svg");
  bottom: -1px;
  height: 2.5vw;
  z-index: 10;
  width: 100%;
  background-size: 100% 2.5vw;
} */

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.phrase {
  background: rgba(0, 0, 0, 0.61);
  margin-top: 3rem;
  border-radius: 20px;
}
.custom_phrase {
  font-family: "Kaushan Script", cursive;
  font-size: 2.1rem;
}

@media (max-width: 576px) {
  .main_heading {
    font-size: 3.5rem;
  }
  #bgc_container {
    min-height: 90vh;
  }
  .phrase {
    background: rgba(0, 0, 0, 0.397);
  }
  .custom_phrase {
    font-size: 1.8rem;
  }
}

@media (orientation: landscape) {
  .custom_btn {
    margin-top: 5vh;
  }
}
