.navigation {
  background-color: black;
  z-index: 1;
}
.main_logo {
  width: 90px;
}

.menu {
  display: flex;
  list-style: none;
  height: 100%;
  align-items: center;
  text-align: center;
}

.menu .menu_item {
  flex-basis: 50%;
  padding: 10px;
  cursor: pointer;
  color: white;
}

.menu .menu_item:hover {
  background-color: red;
}

/* Nav styling */

.black_nav {
  background-color: black !important;
  z-index: 100;
}

.responsive-navbar-nav {
  background-color: brown;
}

.nav_element {
  color: white;
  padding: 10px;
}
.nav_element:hover {
  background: #dc3545;
  color: white;
  text-decoration: none;
}
