.about {
  background-color: white;
}
.custom_h2 {
  font-size: 2.5rem;
  text-align: center;
}
.custom_p {
  font-size: 1.1rem;
}
@media (max-width: 992px) {
  .about {
    padding: 25px;
  }
  .custom_h2 {
    font-size: 2.3rem;
    text-align: left;
  }
}
@media (max-width: 576px) {
  .custom_h2 {
    font-size: 1.5rem;
  }
}
